  .bg-gradient-primary {
      background: linear-gradient(0deg, #c063ff12 50%, #aea9ff45 100%);
      animation: rotateGradient 15s linear infinite;  // Ajusta la duración según tus necesidades
  }
  
  @keyframes rotateGradient {
    0% {
      background: linear-gradient(0deg, #c063ff12 50%, #aea9ff45 100%);
    }
    25% {
      background: linear-gradient(90deg, #c063ff12 50%, #aea9ff45 100%);
    }
    50% {
      background: linear-gradient(180deg, #c063ff12 50%, #aea9ff45 100%);
    }
    75% {
      background: linear-gradient(270deg, #c063ff12 50%, #aea9ff45 100%);
    }
    100% {
      background: linear-gradient(360deg, #c063ff12 50%, #aea9ff45 100%);
    }
  }
  

  .xh-100 {
      height: 100vh;
  }


.logo-spinner {
    right: -200px;
    top: -100px;
    z-index: 1;
    width: 80vh;
    position: absolute;
}

.fixed {
  position: fixed;
  z-index: 0;
}

@media (min-width: 768px) {
    .logo-spinner {
        right:-100px;
        top: -150px
    }
}

@media (min-width: 1280px) {
    .logo-spinner {
      right: 500px;
      top: 100px;
      opacity: 0.4;
    }
}

.logo-spinner img {
    -webkit-filter: blur(30px);
    filter: blur(50px);
    -webkit-transform: scale(10);
    -ms-transform: scale(10);
    transform: scale(10);
    -webkit-animation: translateHex 20s linear infinite alternate;
    animation: translateHex 20s linear infinite alternate
}



  @keyframes translateHex {
    0% {
      -webkit-transform: translateX(0px) translateY(0px);
      transform: translate(0) translateY(0);
    }
    25% {
        -webkit-transform: translateX(20px) translateY(20px) rotate(30deg);
        transform: translate(20px) translateY(20px) rotate(30deg);
    }
    50% {
        -webkit-transform: translateX(40px) translateY(0px);
        transform: translate(40px) translateY(0);
    }

    75% {
        -webkit-transform: translateX(20px) translateY(20px) rotate(-30deg);
        transform: translate(20px) translateY(20px) rotate(-30deg);
    }
    100% {
        -webkit-transform: translateX(-10px) translateY(-10px);
        transform: translate(-10px) translateY(-10px);
    }
  }